import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../images/logo_tiny.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import "./Header.css";


const Header = () => {
  return (
    <header>
      <div className="header-container">
        {/* <NavBar /> */}
        <LogoBar />
      </div>
    </header>
  );
};

const NavBar = () => {
  return (
    <div  className="nav-bar">
      <Link to="/" >
        About
      </Link>
      <Link to="/" >
        Services
      </Link>
      <Link to="/" >
        Projects
      </Link>
    </div>
  );
};

const LogoBar = () => {
  return (
    <div className="logo-bar">
      <Link to="/">
          <img
            style={{ height: "50px" }}
            src={logo}
            alt="GeoNebura Strategies"
            className="navbar-brand"
          />
          <span className="strokeme" style={{ paddingLeft: "5px", paddingRight: "20px" }}>
            GeoNebura Strategies
          </span>
      </Link>


      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/contact-us"
      >
        <MailOutlineIcon />
        Contact
      </Button>
    </div>
  );
};

export default Header;
