import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" align='center'>
      {'Copyright © '}
      <Link color="inherit" href="/">
        GeoNebula Stratergies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {

  return (
    <footer style={{
      padding: 3,
      backgroundColor: 'black',
      color: 'white',
      position: 'fixed',
      width: '100vw',
      bottom: 0,
    }}>
      <Copyright />
    </footer>
  );
}
