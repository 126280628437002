import React, { useState, useEffect } from "react";
import mainPic from "../images/home.jpg";

import OurSulutions from "../components/OurSolutions";

const OurAdvantages = () => {
  const [showLines, setShowLines] = useState(0);
  const advantageList = [
    "High Quality Services",
    "Building Trusting Relationship",
    "Passionate About Work & Community",
  ];
  useEffect(() => {
    setInterval(() => {
      setShowLines((r) => (r === 10 ? 0 : r + 1));
    }, 2000);
  }, []);

  return (
    <div style={{ minWidth: "350px", minHeight: "200px" }}>
      <h3
        className="flowWord"
        style={{
          marginLeft: "2.75rem",
          paddingLeft: "0.5rem",
          marginRight: "0.25rem",
        }}
      >
        Why us?
      </h3>
      <ul>
        {advantageList.slice(0, showLines).map((value, i) => {
          return (
            <li className="m-1 px-1 flowWord" key={i}>
              {value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Home = () => {
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#dddde9",
            textAlign: "center",
            // padding: "0 50px ",
            
          }}
        >
            <div style={{
            maxWidth:"1490px"
            
          }}
            >
          <img
            src={mainPic}
            alt="GeoNebura Strategies"
            style={{
                width:"100%",
            height: "500px",
              // filter: 'sepia(55%)',
              objectFit: "cover",
              objectPosition: "0% 50%",
            }}
          /></div>
          <div style={{ position: "absolute", top: "200px", right: "60px" }}>
            <OurAdvantages />
          </div>
        </div>
      </div>
      <OurSulutions />
    </div>
  );
};

export default Home;
