import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { green } from "@mui/material/colors";

import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import Modal from "@mui/material/Modal";
import CheckIcon from "@mui/icons-material/Check";

const QuestionList = [
  "Database Management",
  "GIS Development",
  "Web Development",
  "Cloud Services - AWS",
  "Cloud Services - Azure",
  "Business Intelligence - Power BI",
  "Data Analytics",
  "Data Visualization",
  "Custom Software Solutions",
];

const buttonSx = {
  cursor: "default",
  position: "absolute",
  top: "30%",
  left: "40%",
  marginTop: "-12px",
  marginLeft: "-12px",
  bgcolor: green[400],
  "&:hover": {
    bgcolor: green[400],
    cursor: "default",
  },
};

const ContactForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const { name, email, message } = event.target;

    // Use your API endpoint URL you copied from the previous step
    const endpoint =
      "https://gtowkgtv0b.execute-api.us-east-1.amazonaws.com/default/sendContactMeEmail";
    // We use JSON.stringify here so the data can be sent as a string via HTTP
    const body = JSON.stringify({
      senderName: name.value,
      senderEmail: email.value,
      message: message.value,
      questions: selectedQuestions,
    });
    const requestOptions = {
      method: "POST",
      body,
    };

    fetch(endpoint, requestOptions)
      .then((response) => {
        if (!response.ok) throw new Error("Error in fetch");
        return response.json();
      })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setFail(true);
      });
  };

  const handleQuestionChange = (event) => {
    setSelectedQuestions(event.target.value); // Update the state
  };

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        {success && (
          <Fab aria-label="success" sx={buttonSx}>
            <CheckIcon />
          </Fab>
        )}

        <Stack spacing={2}>
          <TextField
            required
            name="name"
            label="Name"
            disabled={loading || success}
          />
          <TextField
            type="email"
            required
            name="email"
            label="Email"
            disabled={loading || success}
          />
          <TextField
            name="questions"
            select
            SelectProps={{
              multiple: true,
              value: selectedQuestions, // Bind the selected values
              onChange: handleQuestionChange, // Handle changes
              // renderValue: (selected) => selected.join(", "), // Optional, for display
            }}
            label="I have questions on ..."
            disabled={loading || success}
            helperText="Select one or more topics"
            // Removed fullWidth and added a specific width if necessary
            // sx={{ width: 'desired-width' }} // Un-comment and set the desired width if needed
          >
            {QuestionList.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="message"
            label="Message"
            multiline
            rows={4}
            disabled={loading || success}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || success}
          >
            Send
          </Button>
          <Modal
            open={fail}
            onClose={() => setFail(false)}
            aria-labelledby="send-mail-error"
            aria-describedby="send-mail-error"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <p>Unknown error on sending. Please try again.</p>
            </Box>
          </Modal>
        </Stack>
      </Box>
    </div>
  );
};

export default ContactForm;
