import React from 'react'
import Container from '@material-ui/core/Container';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

export default function Default() {

    return (
        <div style={{ paddingTop: '6rem', paddingBottom: '4rem' }}>
            <Container maxWidth="xs" className=' text-center'>
                <h3 >404 - PAGE NOT FOUND</h3>
                <p >The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <Button variant="outlined" color="primary" className='my-5' > <Link to="/" >GO TO HOMEPAGE</Link></Button>

            </Container>
        </div>
    )

}
