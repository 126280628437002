import React from 'react';

import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

import Header from './components/Header';
import StickyFooter from './components/StickyFooter';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Default from './pages/Default';
import ScrollToTop from './components/ScrollToTop'; //scroll to top when switch pages.
function App() {

  return (
    <div  >
      {/* MUI provides a CssBaseline component to kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Header />
      <ScrollToTop />
      <Switch >
        <Route exact path="/" component={Home} />
        {/*  产品/服务 - Products/Services
 案例 - Case Studies or Projects
  关于我们 - About Us*/}
        <Route path="/contact-us" component={Contact} />
        <Route component={Default} />
      </Switch>
      

      <StickyFooter />
    </div>
  );
}

export default App;
