import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CloudIcon from '@material-ui/icons/Cloud';
import Container from '@material-ui/core/Container';
import AddLocationAltIcon from '@material-ui/icons/AddLocationAlt';
import BackupIcon from '@mui/icons-material/Backup';
import TableChartIcon from '@mui/icons-material/TableChart';
import Stack from '@mui/material/Stack';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import StorageIcon from '@mui/icons-material/Storage';
import BarChartIcon from '@mui/icons-material/BarChart';

function IconCard({ icon, title, children }) {
  return (
    <Card sx={{ width: 275, height: '180px' }}>
      <CardContent>
        <div>
          {icon} {title}
        </div>
        <div variant="body2">
          {children}
        </div>
      </CardContent>
    </Card>
  );
}

export default function OurSulutions() {
  return (<Container maxWidth="md" sx={{ padding: '4rem' }}>
  <h3 style={{ textAlign: 'center' }}>OUR SERVICES</h3>
  Tailored data solutions for your unique needs. We specialize in a range of data services 
  including AWS integration, advanced GIS for geospatial data, comprehensive database management, and insightful analytics with Power BI. 
  Enhance your data capabilities with our personalized and expert consulting approach.

  <Stack
    justifyContent="center"
    alignItems="center"
    direction={{ xs: 'column', sm: 'row' }}
    spacing={{ xs: 1, sm: 2, md: 4 }}
  >
    <IconCard icon={<StorageIcon />} title={'Database'}>
      Comprehensive solutions in SQL, NoSQL, and cloud databases.
    </IconCard>
    <IconCard icon={<BackupIcon />} title={'Amazon Web Services'}>
      Expertise in AWS services like EC2, S3, Lambda, Amplify, DynamoDB, IAM, etc.
    </IconCard>
    <IconCard icon={<AddLocationAltIcon />} title={'GIS Service'}>
      Advanced geospatial data processing and analysis.
    </IconCard>
    <IconCard icon={<TableChartIcon />} title={'Power BI'}>
      Insightful data visualization and analytics.
    </IconCard>
  </Stack>
</Container>
)


}