import React from "react";
import hj from "../images/map.png";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ContactForm from "../components/ContactForm";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { useHistory  } from "react-router-dom";

const people = [
  {
    title: "Cloud Architect",
    src: hj,
  },
  {
    title: "Web Developer",
    src: hj,
  },
  {
    title: "GIS Developer",
    src: hj,
  },
];

// function StaffCard(props) {

//     return (
//         <Card sx={{
//             maxWidth: 345,
//         }}>
//             <CardHeader
//                 avatar={
//                     <Avatar aria-label="staff" sx={{ backgroundColor: '#3c4fbf' }}>
//                         {props.init}
//                     </Avatar>
//                 }
//                 title={props.title}
//                 subheader={props.subtitle}
//             />
//             <CardMedia
//                 sx={{
//                     height: 0,
//                     paddingTop: '56.25%',
//                 }}
//                 image={props.src}
//                 title={props.srcTitle}
//             />
//             <CardContent>
//                 <Typography variant="body2" color="textSecondary" component="p">
//                     {/* {props.details} */}
//                 </Typography>
//             </CardContent>
//         </Card>
//     );
// }

// const OurStaff = ({ staffs }) => {
//     return <Grid container spacing={2} >

//         {staffs.map(item => (
//             <Grid item sm={12} md={6} >
//                 <StaffCard init={item.title.charAt(0).toLocaleUpperCase()} src={item.src} srcTitle={item.title.split(' ')[0]}
//                     title={item.title}
//                 />
//             </Grid>
//         ))}
//     </Grid>

// }

export default function Contact() {
  const history  = useHistory ();

  const handleBack = () => {
    history.goBack(); // This will navigate back

  };

  return (
    <Container maxWidth="md" style={{ padding: "4rem", marginTop: "64px" }}>
      {/* <div style=> */}
      <h3 style={{ textAlign: "center", paddingBottom: "10px" }}>CONTACT US</h3>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{ 
            position: 'absolute', 
            top: '95px', // Adjust this value as needed
            left: '20px', // Adjust this value as needed
            m: 2 
          }}
      >
        Back
      </Button>
      {/* <Grid container spacing={6} > */}
      {/* <Grid item sm={6} md={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <OurStaff staffs={staffs} />

                </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <div style={{ borderLeftStyle: "outset", paddingLeft: "12px" }}>
          <ContactForm />
        </div>
      </Grid>
      {/* </Grid> */}
    </Container>
  );
}
